import { useFormik } from "formik";
import styles from "inventory/product-location-tx/components/form/ProductLocationTxForm.module.scss";
import sharedStyles from "shared/styles/Form.module.scss";
import dataGridStyles from "shared/components/custom-data-grid/CustomDataGrid.module.scss";
import accessItemStyles from "shared/components/access-item/AccessItem.module.scss";
import { Autocomplete, Button, IconButton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingPage from "shared/components/loading/LoadingPage";
import {
  ProductLocationTx,
  ProductLocationTxDetail,
} from "inventory/product-location-tx/types";
import { ProductLocationTxSchema } from "inventory/product-location-tx/validations";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Add, Delete, Save } from "@mui/icons-material";
import TxDetailFormPortal from "inventory/product-location-tx/components/product-location-tx-detail/ProductLocationTxDetailForm";
import useAuthUser from "auth/hooks/useAuthUser";
import { Location } from "locations/types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import MainList from "shared/components/main-list/MainList";
import { IAccessItem } from "shared/components/access-item/AccessItem";
import ConfirmQuantityPortal from "shared/components/confirm-quantity-portal/ConfirmQuantityPortal";
import { COMPONENTS } from "configuration/types";
dayjs.extend(utc);

interface FormProps {
  handleSubmit: (entityObj: ProductLocationTx) => void;
  handleCancel: () => void;
  formData: ProductLocationTx | null;
  loading: boolean;
  locations: Location[] | null;
  operationTypes: { id: number; name: string }[] | null;
  predefinedOperationType?: number;
  newReferenceCode: string;
  onInvolvedLocationChange: (newLocation: Location | null) => void;
  fetchItemsInHierarchy?: (parentId?: string) => Promise<IAccessItem[]>;
}

const ProductLocationTxForm = ({
  handleSubmit,
  handleCancel,
  formData,
  loading,
  locations,
  operationTypes,
  predefinedOperationType,
  newReferenceCode,
  onInvolvedLocationChange,
  fetchItemsInHierarchy,
}: FormProps) => {
  const user = useAuthUser();
  const [txDateValue, setTxDateValue] = useState<dayjs.Dayjs | null>(
    dayjs().startOf("day")
  );
  const formik = useFormik<ProductLocationTx>({
    initialValues: formData ?? {
      id: "",
      currentLocationId: user?.currentLocation?.id ?? null,
      involvedLocationId: null,
      txDate: dayjs().startOf("day").toDate(),
      operationTypeId: predefinedOperationType ?? 1,
      referenceCode: "",
      statusId: 1,
      observations: null,
      referencedTxId: null,
      txDetails: [],
    },
    enableReinitialize: true,
    validationSchema: ProductLocationTxSchema,
    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  const [showProductsSelectionPortal, setShowProductsSelectionPortal] =
    useState<boolean>(false);

  const [
    showConfirmProductQuantityPortal,
    setShowConfirmProductQuantityPortal,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (formData?.txDate) {
      setTxDateValue(dayjs(formData.txDate));
    }
  }, [formData]);

  useEffect(() => {
    console.log("newReferenceCode", newReferenceCode);
    formik.setFieldValue("referenceCode", newReferenceCode?.toString() ?? "");
  }, [newReferenceCode]);

  useEffect(() => {
    if (predefinedOperationType === 3 && locations) {
      const externalLocation = locations.find((i) =>
        i.name.includes("EXTERNA")
      );
      if (
        externalLocation &&
        formik.values.involvedLocationId !== externalLocation.id
      ) {
        formik.setFieldValue("involvedLocationId", externalLocation?.id);
        onInvolvedLocationChange(externalLocation);
      }
    }
  }, [predefinedOperationType, locations]);

  const [selectedTxDetail, setSelectedTxDetail] =
    useState<ProductLocationTxDetail | null>(null);

  const updateTxDetail = (item: ProductLocationTxDetail) => {
    if (item.quantity > 0) {
      item.id = item.productId;

      const currentDetails = formik.values.txDetails
        ? [...formik.values.txDetails]
        : [];

      let itemExists = false;
      const newTxDetails = currentDetails.map((e) => {
        if (e.productId === item.productId) {
          itemExists = true;
          return item;
        }
        return e;
      });

      if (!itemExists) {
        newTxDetails.push(item);
      }

      const sortedTxDetails = newTxDetails.sort((a, b) => {
        if (a.product && b.product) {
          return a.product.description.localeCompare(b.product.description);
        } else {
          return 0;
        }
      });

      formik.setFieldValue("txDetails", sortedTxDetails);
    } else {
      if (item.id) {
        removeTxDetail(item.id);
      }
    }
    setShowProductsSelectionPortal(false);
  };

  const removeTxDetail = (id: string) => {
    const newTxDetails = formik.values.txDetails?.filter((e) => e.id !== id);
    formik.setFieldValue("txDetails", newTxDetails);
  };

  const totalItems = formik.values.txDetails
    ?.map((e) => e.quantity)
    .reduce((a, b) => a + b, 0);

  if (loading) {
    return <LoadingPage></LoadingPage>;
  }

  return (
    <>
      {showProductsSelectionPortal && (
        <TxDetailFormPortal
          handleSubmit={updateTxDetail}
          handleCancel={() => {
            setShowProductsSelectionPortal(false);
          }}
          formData={null}
          products={
            locations?.find((p) => p.id === formik.values.involvedLocationId)
              ?.products || null
          }
          fetchItemsInHierarchy={fetchItemsInHierarchy}
        ></TxDetailFormPortal>
      )}
      {showConfirmProductQuantityPortal && (
        <ConfirmQuantityPortal
          handleSubmit={(quantity) => {
            if (selectedTxDetail)
              updateTxDetail({
                ...selectedTxDetail,
                quantity: quantity,
              });
            setShowConfirmProductQuantityPortal(false);
          }}
          handleCancel={() => setShowConfirmProductQuantityPortal(false)}
        ></ConfirmQuantityPortal>
      )}
      <div className={sharedStyles.form_content}>
        <div>
          <h3>Entradas/Salidas {user?.currentLocation?.name}</h3>
          {formik.values.referencedTxId && (
            <h5 style={{ color: "red" }}>
              Operación referenciada no puede ser editada
            </h5>
          )}
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className={dataGridStyles.tool_bar}>
            <IconButton style={{ backgroundColor: "lightblue" }} type="submit">
              <Save></Save>
            </IconButton>
          </div>
          <div className={sharedStyles.form_section_row}>
            <div className={sharedStyles.form_row}>
              <Autocomplete
                className={sharedStyles.input_field}
                id="operationTypeId"
                readOnly={!!predefinedOperationType}
                blurOnSelect
                options={operationTypes || []}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                value={
                  operationTypes?.find(
                    (o) => o.id === formik.values.operationTypeId
                  ) || null
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  formik.setFieldValue("operationTypeId", value?.id || null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      formik.touched.operationTypeId &&
                      Boolean(formik.errors.operationTypeId)
                    }
                    helperText={
                      formik.touched.operationTypeId &&
                      formik.errors.operationTypeId
                    }
                    label="Operación"
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className={sharedStyles.form_row}>
              <Autocomplete
                className={sharedStyles.input_field}
                id="involvedLocationId"
                blurOnSelect
                readOnly={predefinedOperationType === 3}
                options={locations || []}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                value={
                  locations?.find(
                    (o) => o.id === formik.values.involvedLocationId
                  ) || null
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  formik.setFieldValue("involvedLocationId", value?.id || null);
                  onInvolvedLocationChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      formik.touched.involvedLocationId &&
                      !!formik.errors.involvedLocationId
                    }
                    helperText={
                      formik.touched.involvedLocationId &&
                      formik.errors.involvedLocationId
                    }
                    label={
                      formik.values.operationTypeId === 1
                        ? "Viene de"
                        : formik.values.operationTypeId === 2
                        ? "Destino"
                        : "Venta a"
                    }
                    variant="outlined"
                  />
                )}
              />
            </div>
          </div>
          <div className={sharedStyles.form_section_row}>
            <div className={sharedStyles.form_row}>
              <TextField
                className={sharedStyles.input_field}
                type="string"
                id="referenceCode"
                name="referenceCode"
                label="# Factura"
                autoComplete="no"
                value={formik.values.referenceCode || ""}
                onChange={(e) => {
                  formik.setFieldValue(
                    "referenceCode",
                    e.target.value.trimStart()
                  );
                }}
                error={
                  formik.touched.referenceCode && !!formik.errors.referenceCode
                }
                helperText={
                  formik.touched.referenceCode && formik.errors.referenceCode
                }
                InputLabelProps={{
                  shrink: formik.values.referenceCode != null,
                }}
              ></TextField>
            </div>
            <div className={sharedStyles.form_row}>
              <DatePicker
                className={sharedStyles.input_field}
                label="Fecha"
                format="DD/MM/YYYY"
                value={txDateValue}
                onChange={(e) => {
                  setTxDateValue(e ?? null);
                  formik.setFieldValue("txDate", e?.toDate() ?? null);
                }}
                slotProps={{
                  textField: {
                    id: "txDateField",
                    name: "txDateField",
                    error:
                      formik.touched.txDate && Boolean(formik.errors.txDate),
                    helperText: formik.touched.txDate && formik.errors.txDate,
                  },
                }}
              ></DatePicker>
            </div>
          </div>
          {!formData?.referencedTxId && (
            <div className={dataGridStyles.tool_bar}>
              <IconButton
                style={{ backgroundColor: "lightblue" }}
                onClick={() => {
                  setShowProductsSelectionPortal(true);
                }}
              >
                <Add></Add>
              </IconButton>
            </div>
          )}
          {formik.touched.txDetails && formik.errors.txDetails && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <span style={{ color: "#d32f2f" }}>
                {formik.errors.txDetails.toString()}
              </span>
            </div>
          )}
          <div className={styles.products_container}>
            <MainList
              data={formik.values.txDetails || []}
              loading={false}
              itemImagePath="product.imageUrl"
              itemTitlePath="product.description"
              onItemClick={(item: IAccessItem) => {
                setSelectedTxDetail(item as ProductLocationTxDetail);
                setShowConfirmProductQuantityPortal(true);
              }}
              gridViewFooter={(item: any) => (
                <>
                  <div className={accessItemStyles.footer_item}>
                    <span>
                      <strong>{item.product?.description}</strong>
                    </span>
                    <span>
                      <strong>Cantidad: {item.quantity}</strong>
                    </span>
                  </div>
                </>
              )}
              controlOwnerName={
                COMPONENTS.PRODUCT_LOCATION_TXS + "_DETAILS_FORM"
              }
            ></MainList>
          </div>
          <div className={styles.products_summary}>
            <h4>Total de Productos: {totalItems || 0}</h4>
          </div>

          <div className={sharedStyles.buttons_bar}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={!!formData?.referencedTxId}
            >
              Guardar
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProductLocationTxForm;
