import { useAppSelector } from "store/hooks";
import { useEffect, useState } from "react";
import { RootState } from "store";
import { useParams } from "react-router-dom";
import useActions from "locations/store/useActions";
import useAuthUser from "auth/hooks/useAuthUser";
import accessItemStyles from "shared/components/access-item/AccessItem.module.scss";
import { Location, ProductLocation } from "locations/types";
import ProductLocationFormPortal from "locations/components/product-location-form/ProductLocationForm";
import { Identity } from "shared/types/identity";
import ProductsNotInLocationPagePortal from "../products-not-in-location-list/ProductsNotInLocationListPage";
import { Product } from "inventory/product/types";
import MainList from "shared/components/main-list/MainList";
import { COMPONENTS } from "configuration/types";
import { IAccessItem } from "shared/components/access-item/AccessItem";

const ProductsInLocationListPage = () => {
  const params = useParams();
  const user = useAuthUser();

  const location = useAppSelector<Location | null>(
    (state: RootState) => state.locations.location
  );
  const loadingLocation = useAppSelector<boolean>(
    (state: RootState) => state.locations.loadingLocation
  );

  const loadingProductsInLocation = useAppSelector<boolean>(
    (state: RootState) => state.locations.loadingProductsInLocation
  );

  const {
    fetchLocationById,
    fetchProductsInLocationV2,
    fetchProductsInLocation,
    updateProductLocation,
    addProductsToLocation,
  } = useActions();

  useEffect(() => {
    if (!location && params.id) {
      fetchLocationById(params.id);
    }
  }, []);

  useEffect(() => {
    if (location) {
      // // INFINITE LOOP IF THIS CONDITION IS OMMITED. updateLocationFromStore SET LOCATION IN STORE
      // // SO useEffect WILL BE TRIGGER AGAIN, CREATING AN INFINITE LOOP
      // if (!location.productsInLocation) {
      //   fetchProductsInLocationV2(location).then(() => {});
      //   // updateLocationFromStore(location.id!)
      //   // );
      // }

      if (!location.products) {
        fetchProductsInLocation(location);
      }
    }
  }, [location]);

  const [showProductLocationFormPortal, setShowProductLocationFormPortal] =
    useState<boolean>(false);
  const [
    showProductsNotInLocationPagePortal,
    setShowProductsNotInLocationPagePortal,
  ] = useState<boolean>(false);

  const [selectedProductLocation, setSelectedProductLocation] =
    useState<ProductLocation | null>(null);

  const onSave = (obj: ProductLocation) => {
    updateProductLocation(obj).then(() => {
      setShowProductLocationFormPortal(false);
      if (location) {
        fetchProductsInLocationV2(location);
      }
    });
  };

  const handleAdd = () => {
    setShowProductsNotInLocationPagePortal(true);
  };

  const handleEdit = (obj: Identity) => {
    window.scrollTo(0, 0);
    setSelectedProductLocation(obj as ProductLocation);
    setShowProductLocationFormPortal(true);
  };

  const handleAddProducts = (objs: Product[]) => {
    if (!location) {
      alert("Location not defined");
      return;
    }

    if (objs.length == 0) {
      alert("No Products selected");
      return;
    }

    const productIds = objs.map((e) => e.id!);

    addProductsToLocation(location.id!, productIds);
    setShowProductsNotInLocationPagePortal(false);
  };

  const onSearch = (term: string, data: any) => {
    const searchTerm = term.toUpperCase();
    const result = data?.filter((item: any) => {
      if (item.product?.description?.includes(searchTerm)) {
        return item;
      }
    });

    return result;
  };

  const fetchItemsInHierarchy = async (parentId?: string) => {
    if (location) {
      const products = (await fetchProductsInLocation(
        location,
        parentId
      )) as IAccessItem[];

      return products;
    }
    return [];
  };

  return (
    <>
      {showProductLocationFormPortal && (
        <ProductLocationFormPortal
          handleSubmit={onSave}
          handleCancel={() => {
            setShowProductLocationFormPortal(false);
          }}
          formData={selectedProductLocation}
        ></ProductLocationFormPortal>
      )}
      {showProductsNotInLocationPagePortal && (
        <ProductsNotInLocationPagePortal
          handleAdd={handleAddProducts}
          handleCancel={() => {
            setShowProductsNotInLocationPagePortal(false);
          }}
        ></ProductsNotInLocationPagePortal>
      )}
      <MainList
        data={location?.products ?? []}
        loading={loadingLocation || loadingProductsInLocation}
        itemImagePath="imageUrl"
        itemTitlePath="description"
        selectionMode="single"
        onItemClick={handleEdit}
        onAddClick={handleAdd}
        gridViewFooter={FooterComponent}
        onSearch={onSearch}
        onFetchDataInHierarchy={fetchItemsInHierarchy}
        controlOwnerName={COMPONENTS.LOCATIONS + "_PRODUCTS_IN_LOCATION"}
      ></MainList>
    </>
  );
};

const FooterComponent = (item: any) => {
  return (
    <div className={accessItemStyles.footer_item}>
      <span>
        <strong>{item?.description}</strong>
      </span>
      <span>
        Cantidad: <strong>{item?.quantity}</strong>
      </span>
    </div>
  );
};

export default ProductsInLocationListPage;
